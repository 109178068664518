define("discourse/plugins/discourse-whos-online/discourse/initializers/start-whos-online", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "@ember/service"], function (_exports, _pluginApi, _decorators, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const PLUGIN_ID = "whos-online";
  var _default = _exports.default = {
    name: "start-whos-online",
    initialize(container) {
      const onlineService = container.lookup("service:online-service");
      const siteSettings = container.lookup("site-settings:main");

      // If user not allowed, don't display
      if (!onlineService.get("shouldDisplay")) {
        return;
      }
      const indicatorType = siteSettings.whos_online_avatar_indicator;

      // If feature disabled, don't display
      if (indicatorType === "none") {
        return;
      }

      // Set the html class accordingly
      $("html").addClass(`whos-online-${indicatorType}`);
      (0, _pluginApi.withPluginApi)("0.2", api => {
        var _dec, _obj, _dec2, _obj2;
        api.modifyClass("component:user-card-contents", (_dec = (0, _decorators.default)("user", "onlineService.users.@each"), (_obj = {
          pluginId: PLUGIN_ID,
          onlineService: (0, _service.inject)("online-service"),
          classNameBindings: ["isOnline:user-online"],
          isOnline(user) {
            if (!user) {
              return false;
            }
            return this.onlineService.isUserOnline(user.id);
          }
        }, (_applyDecoratedDescriptor(_obj, "isOnline", [_dec], Object.getOwnPropertyDescriptor(_obj, "isOnline"), _obj)), _obj)));

        // This is a bit hacky, since the user page doesn't currently
        // use components
        api.modifyClass("route:user", (_dec2 = (0, _decorators.observes)("onlineService.users.@each"), (_obj2 = {
          pluginId: PLUGIN_ID,
          onlineService: (0, _service.inject)("online-service"),
          afterModel() {
            this.updateBodyClass();
            return this._super();
          },
          updateBodyClass() {
            const user_id = this.modelFor("user").id;
            const isOnline = this.get("onlineService").isUserOnline(user_id);
            if (isOnline) {
              Ember.$("body").addClass("user-page-online");
            } else {
              Ember.$("body").removeClass("user-page-online");
            }
          },
          deactivate() {
            this._super();
            Ember.$("body").removeClass("user-page-online");
          }
        }, (_applyDecoratedDescriptor(_obj2, "updateBodyClass", [_dec2], Object.getOwnPropertyDescriptor(_obj2, "updateBodyClass"), _obj2)), _obj2)));
        if (siteSettings.whos_online_avatar_indicator_topic_lists) {
          var _dec3, _obj3, _dec4, _obj4;
          api.modifyClass("component:topic-list-item", (_dec3 = (0, _decorators.default)("topic.lastPoster.id", "topic.lastPosterUser.id", "onlineService.users.@each"), (_obj3 = {
            pluginId: PLUGIN_ID,
            onlineService: (0, _service.inject)("online-service"),
            classNameBindings: ["isOnline:last-poster-online"],
            isOnline(lastPosterId, lastPosterUserId) {
              return this.get("onlineService").isUserOnline(lastPosterId || lastPosterUserId);
            }
          }, (_applyDecoratedDescriptor(_obj3, "isOnline", [_dec3], Object.getOwnPropertyDescriptor(_obj3, "isOnline"), _obj3)), _obj3)));
          api.modifyClass("component:latest-topic-list-item", (_dec4 = (0, _decorators.default)("topic.lastPoster.id", "topic.lastPosterUser.id", "onlineService.users.@each"), (_obj4 = {
            pluginId: PLUGIN_ID,
            onlineService: (0, _service.inject)("online-service"),
            classNameBindings: ["isOnline:last-poster-online"],
            isOnline(lastPosterId, lastPosterUserId) {
              return this.get("onlineService").isUserOnline(lastPosterId || lastPosterUserId);
            }
          }, (_applyDecoratedDescriptor(_obj4, "isOnline", [_dec4], Object.getOwnPropertyDescriptor(_obj4, "isOnline"), _obj4)), _obj4)));
        }
        api.modifyClass("component:scrolling-post-stream", {
          pluginId: PLUGIN_ID,
          didInsertElement() {
            this._super();
            this.appEvents.on("whosonline:changed", changedUserIds => {
              changedUserIds.forEach(id => {
                let postIds = this.get("attrs").posts.value.posts.filter(_ref => {
                  let {
                    user_id
                  } = _ref;
                  return user_id === id;
                }).map(post => post.id);
                postIds.forEach(postId => {
                  this.dirtyKeys.keyDirty(`post-${postId}`);
                  this.dirtyKeys.keyDirty(`post-${postId}-avatar-${id}`, {
                    onRefresh: "updateOnline"
                  });
                });
              });
              this.queueRerender();
            });
          },
          willDestroyElement() {
            this.appEvents.off("whosonline:changed");
          }
        });
        api.reopenWidget("post-avatar", {
          buildKey: attrs => `post-${attrs.id}-avatar-${attrs.user_id}`,
          defaultState(attrs) {
            return {
              online: onlineService.isUserOnline(attrs.user_id)
            };
          },
          updateOnline() {
            this.state.online = onlineService.isUserOnline(this.attrs.user_id);
          },
          buildClasses(attrs, state) {
            if (state.online) {
              return "user-online";
            }
            return [];
          }
        });
      });
    }
  };
});