define("discourse/plugins/discourse-whos-online/discourse/components/whos-online", ["exports", "@ember/component", "@ember/service", "discourse-common/utils/decorators"], function (_exports, _component, _service, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("online.users.length"), _dec2 = (0, _decorators.default)("online.users.@each"), _dec3 = (0, _decorators.default)("online.users.length"), _dec4 = (0, _decorators.default)("online.users.length"), (_obj = {
    online: (0, _service.inject)("online-service"),
    showWhosOnline() {
      // If the number of users is less than the minimum, and it's set to hide, hide it
      if (this.online.users.length < this.siteSettings.whos_online_minimum_display && this.siteSettings.whos_online_hide_below_minimum_display) {
        return false;
      }
      return this.online.shouldDisplay;
    },
    users() {
      return this.online.users.slice(0, this.siteSettings.whos_online_maximum_display);
    },
    isLong() {
      return this.online.users.length >= this.siteSettings.whos_online_collapse_threshold;
    },
    isUsers() {
      return this.online.users.length >= this.siteSettings.whos_online_minimum_display;
    }
  }, (_applyDecoratedDescriptor(_obj, "showWhosOnline", [_dec], Object.getOwnPropertyDescriptor(_obj, "showWhosOnline"), _obj), _applyDecoratedDescriptor(_obj, "users", [_dec2], Object.getOwnPropertyDescriptor(_obj, "users"), _obj), _applyDecoratedDescriptor(_obj, "isLong", [_dec3], Object.getOwnPropertyDescriptor(_obj, "isLong"), _obj), _applyDecoratedDescriptor(_obj, "isUsers", [_dec4], Object.getOwnPropertyDescriptor(_obj, "isUsers"), _obj)), _obj)));
});